import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { nanoid } from 'nanoid'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

import { AppState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { editActions } from '../../redux/actions/editAction'

import Header from './EditHeader'
import Content from './Content'
import Dropzone from './Dropzone'
import WatermarkSetting from './WatermarkSetting'
import ModalWithLinearProgress from '../../components/ModalWithLinearProgress'

import {
  DialogProps,
  Group as GroupType,
  PhotoCount,
  WatermarkInfo,
} from '../../utils/Types'
import {
  alphaBlend,
  asyncImageReader,
  paddingLeft,
  readImageFile,
  createUploadData,
} from '../../utils/methods'
import Modal from '../../components/Modal'
import API from '../../utils/api'
import history from '../../utils/history'
import Dialog from '../../components/Dialog'

type Props = RouteComponentProps<{ id: string }>

const Edit: React.FC<Props> = (props: Props) => {
  const { getAccessTokenSilently } = useAuth0()
  const dispatch = useDispatch()
  const groups = useSelector((state: AppState) => state.edit.groups)
  const photoSize = useSelector((state: AppState) => state.edit.photoSize)
  const watermarkInfo = useSelector(
    (state: AppState) => state.edit.watermarkInfo
  )

  const [projectId, setProjectId] = useState(props.match.params.id)
  const projectName = useSelector((state: AppState) => state.edit.projectName)
  const [isReading, setIsReadeing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [readProggress, setReadProggress] = useState(0)
  const [isWatermarkOpen, setIsWatermarkOpen] = useState(false)

  const [photoCount, setPhotoCount] = useState<PhotoCount>({
    photo: 0,
    barcode: 0,
    uploaded: 0,
  })
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    title: '',
    content: '',
    color: 'primary',
  })

  const getProject = async (): Promise<void> => {
    if (projectId === 'new') {
      return
    }

    setIsLoading(true)
    try {
      const token = await getAccessTokenSilently({
        audience: 'http://localhost:3001',
        scope: 'read:project',
      })

      const res = await API.get('project', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: projectId,
        },
      })

      if (res.data.result) {
        if (res.data.watermark !== null) {
          dispatch(editActions.updateWatermarkInfo(res.data.watermark))
        }
        dispatch(editActions.updateProjectName(res.data.project.name))
        dispatch(editActions.updateGroups(res.data.project.groups))
        dispatch(
          editActions.updatePhotoSize({
            width: res.data.project.width,
            height: res.data.project.height,
          })
        )

        setPhotoCount({
          photo: res.data.totalPhotoCnt,
          barcode: 0,
          uploaded: res.data.totalPhotoCnt,
        })
      } else {
        openDialog('projectNotFound')
      }
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (projectId === 'new') {
      dispatch(editActions.clearWatermarkInfo())
      return
    }

    getProject()
  }, [])

  const openDialog = React.useCallback(
    (type: string, msg?: React.ReactNode): void => {
      const dialog: DialogProps = {
        open: true,
        title: 'エラー',
        content: '',
        color: 'secondary',
        onClickOk: closeDialog,
      }

      switch (type) {
        case 'projectNotFound': // プロジェクトなし
          dialog.content = (
            <span>
              プロジェクトが見つかりませんでした。
              <br />
              一覧に戻ります。
            </span>
          )
          dialog.onClickOk = () => {
            props.history.push('/pet')
          }
          break
        case 'errorDownload': // ダウンロードエラー
          dialog.content = 'ダウンロード中にエラーが発生しました。'
          break
        case 'errorUpload': // アップロードエラー
          dialog.content = 'アップロード中にエラーが発生しました。'
          break
        case 'overPhotoCnt': // 読込上限オーバー
          dialog.content = `総読込枚数の最大値(${process.env.REACT_APP_READ_MAX}枚)を超えています。`
          break
        case 'groupNameDuplicated': // グループ名重複
          dialog.content = msg
          break
        default:
          dialog.open = false
      }

      setDialogProps(dialog)
    },
    []
  )

  const closeDialog = React.useCallback((): void => {
    setDialogProps({
      open: false,
      title: '',
      content: '',
      color: 'primary',
    })
  }, [])

  const toggleWatermarkOpen = useCallback((): void => {
    setIsWatermarkOpen(!isWatermarkOpen)
  }, [isWatermarkOpen])

  const execComposition = async (
    newWatermarkInfo: WatermarkInfo
  ): Promise<void> => {
    dispatch(editActions.updateWatermarkInfo(newWatermarkInfo))

    toggleWatermarkOpen()
  }

  const deleteAllPhotos = React.useCallback((): void => {
    dispatch(editActions.updateGroups([]))
    setPhotoCount({
      photo: 0,
      barcode: 0,
      uploaded: 0,
    })
  }, [dispatch])

  const addPhotos = React.useCallback(
    async (photos: File[]) => {
      if (
        Number(process.env.REACT_APP_READ_MAX) <
        photos.length + photoCount.photo
      ) {
        openDialog('overPhotoCnt')
        return
      }

      setReadProggress(0)
      setIsReadeing(true)

      const newPhotoCount = Object.assign({}, photoCount)
      newPhotoCount.photo += photos.length
      setPhotoCount(newPhotoCount)

      const date = new Date()
      const groupName =
        'group_' +
        paddingLeft(date.getHours(), '0', 2) +
        paddingLeft(date.getMinutes(), '0', 2) +
        paddingLeft(date.getSeconds(), '0', 2)

      const newGroup: GroupType = {
        id: nanoid(20),
        name: groupName,
        photos: [],
        photoCnt: photos.length,
        isHeadBarcode: false,
        isError: false,
      }

      for (let i = 0; i < photos.length; i++) {
        const readRes = await readImageFile(photos[i], photoSize)

        newGroup.photos.push({
          id: nanoid(10),
          name: paddingLeft(i + 1, '0', 3),
          src: readRes,
        })

        setReadProggress(((i + 1) / photos.length) * 100)
      }

      setTimeout(() => {
        dispatch(editActions.updateGroups([...groups, newGroup]))

        setIsReadeing(false)
      }, 1000)
    },
    [dispatch, photoCount, photoSize, groups]
  )

  const checkGroupNameDup = React.useCallback((): boolean => {
    // グループ名の重複チェック
    const groupNameList: string[] = []
    const groupNameDupList: string[] = []

    for (const group of groups) {
      if (groupNameList.indexOf(group.name) < 0) {
        // 重複なし
        groupNameList.push(group.name)
      } else if (groupNameDupList.indexOf(group.name) < 0) {
        // 初重複
        groupNameDupList.push(group.name)
      }
    }

    if (0 < groupNameDupList.length) {
      // 重複しているグループをエラー表示
      const newGroups = groups.map((group, _idx) => {
        if (0 <= groupNameDupList.indexOf(group.name)) {
          group.isError = true
          return Object.assign({}, group)
        }

        return group
      })
      dispatch(editActions.updateGroups(newGroups))

      const msg = React.createElement('span', { key: 0 }, [
        '重複しているグループ名があります。',
        React.createElement('br', { key: 1 }, null),
        `[${groupNameDupList.join(', ')}]`,
      ])
      openDialog('groupNameDuplicated', msg)

      return false
    }

    return true
  }, [groups, dispatch, openDialog])

  const download = async (): Promise<void> => {
    setIsDownloading(true)

    if (!checkGroupNameDup()) {
      setIsDownloading(false)
      return
    }

    const jsZip = new JSZip()

    const watermark = await asyncImageReader(watermarkInfo.src)

    let noNameCnt = 0
    let photoCnt = 0
    for (const group of groups) {
      let groupName = group.name
      if (groupName === '') {
        noNameCnt++
        groupName = `no_name_${paddingLeft(noNameCnt, '0', 2)}`
      }

      for (const photo of group.photos) {
        const image = await asyncImageReader(photo.src)
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const ctx = canvas.getContext('2d')

        if (ctx) {
          ctx.drawImage(image, 0, 0)
          let dataSource = canvas.toDataURL('image/jpeg')
          if (photo.name === '001' && watermark.src !== '') {
            dataSource = await alphaBlend(
              canvas.toDataURL('image/jpeg'),
              watermark.src,
              watermarkInfo.position,
              watermarkInfo.opacity,
              watermarkInfo.size
            )
          }

          jsZip.file(
            `${groupName}_${photo.name}.jpg`,
            dataSource.split(',')[1],
            {
              base64: true,
            }
          )

          photoCnt++
        }
      }
    }

    const fd = new FormData()
    fd.append('photoCnt', JSON.stringify({ data: photoCnt }))
    fd.append('watermark', JSON.stringify({ data: watermarkInfo }))

    const token = await getAccessTokenSilently({
      audience: 'http://localhost:3001',
    })

    const result = await API.post('download', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }).catch(() => {
      return { data: { result: false } }
    })

    setIsDownloading(false)

    if (result.data.result) {
      jsZip.generateAsync({ type: 'blob' }).then((content: Blob) => {
        saveAs(content, `${projectName}.zip`)
      })

      if (watermarkInfo.src !== '') {
        dispatch(editActions.updateLatestWatermark(watermarkInfo.src))
      }
    } else {
      openDialog('errorDownload')
    }
  }

  const upload = async (): Promise<void> => {
    setIsUploading(true)

    if (!checkGroupNameDup()) {
      setIsUploading(false)
      return
    }

    const token = await getAccessTokenSilently({
      audience: 'http://localhost:3001',
      scope: 'write:project',
    })

    const result = await API.post(
      'project',
      await createUploadData(
        projectId,
        projectName,
        photoSize,
        groups,
        watermarkInfo
      ),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    ).catch(() => {
      return { data: { result: false } }
    })

    setIsUploading(false)

    if (result.data.result) {
      setProjectId(result.data.id)
      getProject()

      if (watermarkInfo.src !== '') {
        dispatch(editActions.updateLatestWatermark(watermarkInfo.src))
      }

      history.push(`/Edit/${result.data.id}`)
    } else {
      openDialog('errorUpload')
    }
  }

  const backToList = React.useCallback((): void => {
    props.history.push('/')
  }, [props.history])

  return (
    <div>
      <Header
        photoCount={photoCount}
        onPhotoCountChange={setPhotoCount}
        onDeleteAllPhotos={deleteAllPhotos}
        onAddPhotos={addPhotos}
        onBackToList={backToList}
        onWatermarkClick={toggleWatermarkOpen}
        onDownload={download}
        onUpload={upload}
      />
      <Content />
      <Dropzone onAddPhotos={addPhotos} />
      <ModalWithLinearProgress
        open={isReading}
        progress={readProggress}
        message="読込中..."
        color="primary"
      />
      <Modal open={isLoading} message="データ取得中..." color="primary" />
      <Modal open={isDownloading} message="ZIP作成中..." color="primary" />
      <Modal open={isUploading} message="サーバに保存中..." color="primary" />
      <Dialog
        open={dialogProps.open}
        title={dialogProps.title}
        content={dialogProps.content}
        color={dialogProps.color}
        onClickCancel={dialogProps.onClickCancel}
        onClickOk={dialogProps.onClickOk}
      />
      <WatermarkSetting
        open={isWatermarkOpen}
        onClose={toggleWatermarkOpen}
        onExecComposition={execComposition}
      />
    </div>
  )
}

export default Edit
