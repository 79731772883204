import React, { useCallback, useState } from 'react'
import { nanoid } from 'nanoid'

import store from '../../redux/store'
import { useDispatch } from 'react-redux'
import { editActions } from '../../redux/actions/editAction'

import Group from './Group'
import Dialog from '../../components/Dialog'

import { DialogProps, Group as GroupType } from '../../utils/Types'
import { deepCopy, findGroupById, paddingLeft } from '../../utils/methods'

const Content: React.FC = () => {
  const dispatch = useDispatch()
  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    title: '',
    content: '',
    color: 'primary',
  })

  const closeDialog = useCallback((): void => {
    setDialogProps({
      open: false,
      title: '',
      content: '',
      color: 'primary',
    })
  }, [])

  const releaseGroup = (groupId: string): void => {
    const groups = store.getState().edit.groups
    const [fromGroup, targetGroupIdx] = findGroupById(groups, groupId)
    const toGroup = groups[targetGroupIdx - 1]

    fromGroup.photos.forEach((photo) => {
      toGroup.photos.push(photo)
    })

    const newGroup: GroupType = deepCopy(toGroup)
    newGroup.photos.forEach((photo, index) => {
      photo.name = paddingLeft(index + 1, '0', 3)
    })

    let first: GroupType[] = []
    if (2 <= targetGroupIdx) {
      first = groups.slice(0, targetGroupIdx - 1)
    }
    const last = groups.slice(targetGroupIdx + 1)

    dispatch(editActions.updateGroups([...first, newGroup, ...last]))
  }

  const splitGroup = (groupId: string, photoId: string): void => {
    const groups = store.getState().edit.groups
    const [targetGroup, targetGroupIdx] = findGroupById(groups, groupId, true)

    const targetPhotoIdx = targetGroup.photos.findIndex(
      (photo) => photo.id === photoId
    )

    if (targetPhotoIdx === 0) {
      setDialogProps({
        open: true,
        title: '警告',
        content: 'グループの先頭で分割はできません。',
        color: 'primary',
        onClickOk: closeDialog,
      })
      return
    }

    const date = new Date()
    const groupName =
      'group_' +
      paddingLeft(date.getHours(), '0', 2) +
      paddingLeft(date.getMinutes(), '0', 2) +
      paddingLeft(date.getSeconds(), '0', 2)

    const newGroup: GroupType = {
      id: nanoid(20),
      name: groupName,
      photos: targetGroup.photos.slice(targetPhotoIdx),
      photoCnt: 0,
      isHeadBarcode: false,
      isError: false,
    }
    newGroup.photos.forEach((photo, index) => {
      photo.name = paddingLeft(index + 1, '0', 3)
    })

    targetGroup.photos = targetGroup.photos.slice(0, targetPhotoIdx)

    const first = groups.slice(0, targetGroupIdx)
    const last = groups.slice(targetGroupIdx + 1)

    dispatch(
      editActions.updateGroups([...first, targetGroup, newGroup, ...last])
    )
  }

  const execDuplicateGroup = (groupId: string): void => {
    const groups = store.getState().edit.groups

    const [newGroup, targetGroupIdx] = findGroupById(groups, groupId, true)

    const date = new Date()
    const groupName =
      'group_' +
      paddingLeft(date.getHours(), '0', 2) +
      paddingLeft(date.getMinutes(), '0', 2) +
      paddingLeft(date.getSeconds(), '0', 2)

    newGroup.id = nanoid(20)
    newGroup.name = groupName
    newGroup.photos.forEach((photo) => {
      photo.id = nanoid(20)
    })
    newGroup.isError = false

    const first = groups.slice(0, targetGroupIdx + 1)
    const last = groups.slice(targetGroupIdx + 1)

    dispatch(editActions.updateGroups([...first, newGroup, ...last]))

    closeDialog()
  }

  const execDeleteGroup = (groupId: string): void => {
    const groups = store.getState().edit.groups

    const targetGroupIdx = groups.findIndex((group) => group.id === groupId)

    const first = groups.slice(0, targetGroupIdx)
    const last = groups.slice(targetGroupIdx + 1)

    dispatch(editActions.updateGroups([...first, ...last]))

    closeDialog()
  }

  const confirmDuplicateGroup = (groupId: string, groupName: string): void => {
    setDialogProps({
      open: true,
      title: '確認',
      content: `本当にこのグループ(${groupName})をコピーしますか？`,
      color: 'primary',
      onClickCancel: closeDialog,
      onClickOk: () => execDuplicateGroup(groupId),
    })
  }

  const confirmDeleteGroup = (groupId: string, groupName: string): void => {
    setDialogProps({
      open: true,
      title: '確認',
      content: `本当にこのグループ(${groupName})を削除しますか？`,
      color: 'secondary',
      onClickCancel: closeDialog,
      onClickOk: () => execDeleteGroup(groupId),
    })
  }

  const updateGroup = (newGroup: GroupType): void => {
    const groups = store.getState().edit.groups
    const oldGroup = groups.find((group) => group.id === newGroup.id)

    dispatch(editActions.updateGroup(newGroup))

    if (oldGroup === undefined) {
      return
    }

    if (oldGroup.name !== newGroup.name) {
      const dupGroups = groups.filter(
        (group) => group.id !== oldGroup.id && group.name === oldGroup.name
      )
      if (1 < dupGroups.length) {
        return
      }

      dupGroups.forEach((group, _idx) => {
        group.isError = false
        dispatch(editActions.updateGroup(Object.assign({}, group)))
      })
    }
  }

  return (
    <div>
      {store.getState().edit.groups.map((group: GroupType, index: number) => (
        <Group
          key={group.id}
          isTop={index === 0 ? true : false}
          group={group}
          onReleaseGroup={releaseGroup}
          onDuplicateGroup={confirmDuplicateGroup}
          onDeleteGroup={confirmDeleteGroup}
          onSplitGroup={splitGroup}
          onUpdateGroup={updateGroup}
        />
      ))}
      <Dialog
        open={dialogProps.open}
        title={dialogProps.title}
        content={dialogProps.content}
        color={dialogProps.color}
        onClickCancel={dialogProps.onClickCancel}
        onClickOk={dialogProps.onClickOk}
      />
    </div>
  )
}

export default Content
