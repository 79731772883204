import actionCreatorFactory from 'typescript-fsa'
import { Group, PhotoSize, WatermarkInfo } from '../../utils/Types'

const actionCreator = actionCreatorFactory()

export const editActions = {
  updatePhotoSize: actionCreator<PhotoSize>('UPDATE_PHOTO_SIZE'),
  updateProjectName: actionCreator<string>('UPDATE_PROJECT_NAME'),
  updateGroups: actionCreator<Group[]>('UPDATE_GROUPS'),
  updateGroup: actionCreator<Group>('UPDATE_GROUP'),
  updateWatermarkInfo: actionCreator<WatermarkInfo>('UPDATE_WATERMARK_INFO'),
  clearWatermarkInfo: actionCreator('CLEAR_WATERMARK_INFO'),
  resetStore: actionCreator('RESET_STORE'),
  updateLatestWatermark: actionCreator<string>('UPDATE_LATEST_WATERMARK'),
}
