import React, { useState } from 'react'
import Quagga, { QuaggaJSConfigObject } from '@ericblade/quagga2'
import { nanoid } from 'nanoid'

import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../redux/store'
import { editActions } from '../../redux/actions/editAction'

import { Button, Grid, TextField } from '@material-ui/core'
import { createStyles, withStyles, makeStyles } from '@material-ui/core/styles'
import { teal } from '@material-ui/core/colors'
import {
  GetApp as GetAppIcon,
  CloudUpload as CloudUploadIcon,
} from '@material-ui/icons'

import Dialog from '../../components/Dialog'
import ModalWithLinearProgress from '../../components/ModalWithLinearProgress'

import { DialogProps, Group as GroupType, PhotoCount } from '../../utils/Types'
import { paddingLeft } from '../../utils/methods'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingBottom: '10px',
      background: 'black',
      borderBottom: '1px solid #c3c3c3',
      textAlign: 'center',
      marginBottom: '10px',
    },
    spanText: {
      color: 'white',
      display: 'block',
      paddingTop: '7px',
      fontSize: '14px',
    },
    textfield: {
      '& input': {
        padding: '8.5px 14px',
      },
    },
    inputFileHide: {
      opacity: '0',
      appearance: 'none',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  })
)

const TealButton = withStyles(() => ({
  root: {
    backgroundColor: teal[200],
    '&:hover': {
      backgroundColor: '#5B8E89',
    },
  },
}))(Button)

const quaggaConfig: QuaggaJSConfigObject = {
  inputStream: {
    size: 800,
    singleChannel: false,
  },
  locator: {
    patchSize: 'medium',
    halfSample: true,
  },
  decoder: {
    readers: [
      {
        format: 'code_128_reader',
        config: { supplements: [] },
      },
    ],
  },
  locate: true,
  src: undefined,
}

interface Props {
  photoCount: PhotoCount
  onPhotoCountChange: (photoCount: PhotoCount) => void
  onDeleteAllPhotos: () => void
  onAddPhotos: (photos: File[]) => void
  onWatermarkClick: () => void
  onBackToList: () => void
  onDownload: () => void
  onUpload: () => void
}

const Header: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const groups = useSelector((state: AppState) => state.edit.groups)
  const photoSize = useSelector((state: AppState) => state.edit.photoSize)
  const projectName = useSelector((state: AppState) => state.edit.projectName)
  const watermarkInfo = useSelector(
    (state: AppState) => state.edit.watermarkInfo
  )

  const [dialogProps, setDialogProps] = useState<DialogProps>({
    open: false,
    title: '',
    content: '',
    color: 'primary',
  })
  const [photoWidth, setPhotoWidth] = useState(photoSize.width)
  const [photoHeight, setPhotoHeight] = useState(photoSize.height)
  const [isReadingBarcode, setIsReadingBarcode] = useState(false)
  const [readProggress, setReadProggress] = useState(0)

  const openDialog = (type: string): void => {
    const dialog: DialogProps = {
      open: true,
      title: '確認',
      content: '',
      color: 'primary',
      onClickCancel: closeDialog,
    }

    switch (type) {
      case 'photoSize': // 写真サイズ変更
        dialog.content = (
          <span>
            サイズを変更するとウォーターマークの設定が初期化されます。
            <br />
            よろしいですか?
          </span>
        )
        dialog.color = 'secondary'
        dialog.onClickCancel = cancelPhotoSizeChange
        dialog.onClickOk = execPhotoSizeChange
        break
      case 'deleteAll': // 全画像の一括削除
        dialog.content = '本当にすべての画像を削除しますか？'
        dialog.color = 'secondary'
        dialog.onClickOk = execDeleteAll
        break
      case 'deleteZero': // 000をすべて削除
        dialog.content = '本当に000の画像を削除しますか？'
        dialog.color = 'secondary'
        dialog.onClickOk = execDeleteZero
        break
      case 'readBarcode': // バーコード自動読み取り
        dialog.content = 'バーコード自動読み取りを開始しますか？'
        dialog.onClickOk = readBarcodeHandler
        break
      case 'download': // ダウンロード
        dialog.content = (
          <span>
            ダウンロードを開始します。
            <br />
            よろしいですか？
          </span>
        )
        dialog.onClickOk = execDownload
        break
      case 'upload': // アップロード
        dialog.content = (
          <span>
            アップロードを開始します。
            <br />
            よろしいですか？
          </span>
        )
        dialog.onClickOk = execUpload
        break
      default:
        dialog.open = false
    }

    setDialogProps(dialog)
  }

  const closeDialog = React.useCallback((): void => {
    setDialogProps({
      open: false,
      title: '',
      content: '',
      color: 'primary',
    })
  }, [])

  const toggleWatermarkOpen = (): void => {
    const photo = groups[0].photos.find((photo) => photo.name !== '000')
    if (photo && photo.src) {
      props.onWatermarkClick()
    }
  }

  const onWidthChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let width = event.target.value.replace(new RegExp(/^0+/g), '')

    if (width === '') {
      width = '0'
    }

    // 先頭0以外 かつ 数字のみ
    const pattern = /^([1-9]\d*|0)$/
    if (pattern.test(width) && Number(width) <= 1000) {
      setPhotoWidth(parseInt(width))
    }
  }

  const onHeightChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let height = event.target.value.replace(new RegExp(/^0+/g), '')

    if (height === '') {
      height = '0'
    }

    // 先頭0以外 かつ 数字のみ
    const pattern = /^([1-9]\d*|0)$/
    if (pattern.test(height) && Number(height) <= 1000) {
      setPhotoHeight(parseInt(height))
    }
  }

  const onPhotoSizeBlurHandler = (): void => {
    if (watermarkInfo.src !== '') {
      if (photoWidth !== photoSize.width || photoHeight !== photoSize.height) {
        openDialog('photoSize')
        return
      }
    }

    dispatch(
      editActions.updatePhotoSize({
        width: photoWidth,
        height: photoHeight,
      })
    )
  }

  const cancelPhotoSizeChange = React.useCallback((): void => {
    setPhotoWidth(photoSize.width)
    setPhotoHeight(photoSize.height)

    closeDialog()
  }, [photoSize, closeDialog])

  const execPhotoSizeChange = (): void => {
    closeDialog()

    dispatch(
      editActions.updatePhotoSize({
        width: photoWidth,
        height: photoHeight,
      })
    )
    dispatch(editActions.clearWatermarkInfo())
  }

  const execDeleteAll = (): void => {
    props.onDeleteAllPhotos()
    closeDialog()
  }

  const execDeleteZero = (): void => {
    const newGroups = groups.map((group) => {
      if (!group.isHeadBarcode) {
        return group
      }

      const newGroup = Object.assign({}, group)
      newGroup.photos.shift()
      newGroup.isHeadBarcode = false

      return newGroup
    })

    dispatch(editActions.updateGroups(newGroups))

    closeDialog()
  }

  const execDownload = (): void => {
    props.onDownload()
    closeDialog()
  }

  const execUpload = (): void => {
    props.onUpload()
    closeDialog()
  }

  const fileSelectHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const fileList = event.currentTarget.files

    if (fileList === null) {
      return
    }

    const files: File[] = []
    Array.from(fileList).forEach((file) => {
      files.push(file)
    })

    props.onAddPhotos(files)
  }

  const projectNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(
      editActions.updateProjectName(
        event.target.value.replace(/[¥¥/:?*"<>|]/g, '')
      )
    )
  }

  const readBarcodeHandler = async (): Promise<void> => {
    closeDialog()
    setReadProggress(0)
    setIsReadingBarcode(true)

    let totalPhotoCnt = 0
    groups.forEach((group) => {
      totalPhotoCnt += group.photos.length
    })

    let readPhotoCnt = 0
    let readBarcodeCnt = 0
    const newGroups: GroupType[] = []
    for (const group of groups) {
      const photos = [...group.photos]

      let newGroup: GroupType = group
      newGroup.photos = []
      for (const photo of photos) {
        quaggaConfig.src = photo.src
        const result = await Quagga.decodeSingle(quaggaConfig)

        if (result?.codeResult && result.codeResult.code) {
          if (newGroup.photos.length !== 0) {
            newGroups.push(newGroup)
          }

          photo.name = '000'

          newGroup = {
            id: nanoid(20),
            name: result.codeResult.code,
            photos: [photo],
            photoCnt: 1,
            isHeadBarcode: true,
            isError: false,
          }

          readBarcodeCnt++
        } else {
          photo.name = paddingLeft(newGroup.photos.length, '0', 3)
          newGroup.photos.push(photo)
        }

        readPhotoCnt++
        setReadProggress((readPhotoCnt * 100) / totalPhotoCnt)
      }

      newGroups.push(newGroup)
    }

    const newPhotoCount = Object.assign({}, props.photoCount)
    newPhotoCount.barcode += readBarcodeCnt
    props.onPhotoCountChange(newPhotoCount)

    setTimeout(() => {
      dispatch(editActions.updateGroups(newGroups))

      setIsReadingBarcode(false)
    }, 1000)
  }

  const getDisplayPhotoCnt = (): string => {
    let result = `元画像数 ${props.photoCount.photo}`
    result += ' / '
    result += `読み取りバーコード ${props.photoCount.barcode}`

    if (process.env.REACT_APP_MODE === '0') {
      result += ' / '
      result += `保存数 ${props.photoCount.uploaded}`
    }

    return result
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item md={1}>
          <Button
            color="default"
            variant="contained"
            fullWidth
            onClick={props.onBackToList}
          >
            戻る
          </Button>
        </Grid>
        <Grid item md={2}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid container justify="space-between">
                <Grid item md={5}>
                  <TextField
                    id="photoWidth"
                    label="横"
                    variant="outlined"
                    size="small"
                    value={photoWidth}
                    onChange={onWidthChangeHandler}
                    onBlur={onPhotoSizeBlurHandler}
                    className={classes.textfield}
                    title="最大1000まで"
                    disabled={groups.length !== 0 ? true : false}
                  />
                </Grid>
                <Grid item md={1}>
                  <span className={classes.spanText}>×</span>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    id="photoHeight"
                    label="縦"
                    variant="outlined"
                    size="small"
                    value={photoHeight}
                    onChange={onHeightChangeHandler}
                    onBlur={onPhotoSizeBlurHandler}
                    className={classes.textfield}
                    title="最大1000まで"
                    disabled={groups.length !== 0 ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => openDialog('deleteAll')}
                disabled={groups.length === 0 ? true : false}
              >
                全画像の一括削除
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <TealButton variant="contained" fullWidth>
                    ファイル選択
                    <input
                      type="file"
                      className={classes.inputFileHide}
                      accept="image/jpeg,image/png,image/gif"
                      multiple
                      onChange={fileSelectHandler}
                    />
                  </TealButton>
                </Grid>
                <Grid item md={6}>
                  <TealButton
                    variant="contained"
                    fullWidth
                    onClick={() => openDialog('readBarcode')}
                    disabled={groups.length === 0 ? true : false}
                  >
                    ﾊﾞｰｺｰﾄﾞ自動読み取り
                  </TealButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <TealButton
                    variant="contained"
                    fullWidth
                    onClick={() => openDialog('deleteZero')}
                    disabled={groups.length === 0 ? true : false}
                  >
                    000をすべて削除
                  </TealButton>
                </Grid>
                <Grid item md={6}>
                  <TealButton
                    variant="contained"
                    fullWidth
                    onClick={toggleWatermarkOpen}
                    disabled={groups.length === 0 ? true : false}
                  >
                    ｳｫｰﾀｰﾏｰｸ自動合成
                  </TealButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item md={10}>
              <TextField
                label="プロジェクト名"
                variant="outlined"
                size="small"
                fullWidth
                className={classes.textfield}
                value={projectName}
                onChange={projectNameChangeHandler}
              />
            </Grid>
            <Grid item md={10}>
              <span className={classes.spanText}>{getDisplayPhotoCnt()}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2}>
          <Grid container direction="column" spacing={1}>
            <Grid item md={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<GetAppIcon />}
                onClick={() => openDialog('download')}
                disabled={
                  groups.length === 0 || projectName === '' ? true : false
                }
              >
                ダウンロード
              </Button>
            </Grid>
            {process.env.REACT_APP_MODE === '0' ? (
              <Grid item md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  onClick={() => openDialog('upload')}
                  disabled={
                    groups.length === 0 || projectName === '' ? true : false
                  }
                >
                  サーバに保存
                </Button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={dialogProps.open}
        title={dialogProps.title}
        content={dialogProps.content}
        color={dialogProps.color}
        onClickCancel={dialogProps.onClickCancel}
        onClickOk={dialogProps.onClickOk}
      />
      <ModalWithLinearProgress
        open={isReadingBarcode}
        progress={readProggress}
        message="読み取り中..."
        color="primary"
      />
    </div>
  )
}

export default React.memo(Header)
