import React, { useRef } from 'react'

import { AppState } from '../../redux/store'
import { useSelector } from 'react-redux'

import { Paper } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import { Photo as PhotoType, WatermarkInfo } from '../../utils/Types'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-block',
      margin: '0 10px 10px 0',
    },
    title: {},
    img: {
      maxWidth: '163px',
      maxHeight: '163px',
      objectFit: 'contain',
    },
    photo: {
      padding: '5px',
      width: '163px',
      height: '185px',
      display: 'inline-block',
      position: 'relative',
    },
    overlay: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      opacity: 0,
      flexDirection: 'column',
      alignItems: 'flex-end',
      '&:hover': {
        opacity: 1,
      },
    },
    actionIcon: {
      width: '50px',
      height: '50px',
      marginRight: '5px',
    },
    enlargeBody: {
      position: 'relative',
      '&:focus': {
        outline: 'none',
      },
    },
    enlargeImg: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    enlargeWatermark: {
      position: 'absolute',
    },
    imageContainer: {
      marginTop: '2px',
      position: 'relative',
    },
    watermark: {
      position: 'absolute',
    },
  })
)

const getWatermarkStyle = (
  watermarkInfo: WatermarkInfo,
  previewSize: number
) => {
  let ratio = 0
  if (watermarkInfo.previewSize.width < watermarkInfo.previewSize.height) {
    ratio = previewSize / watermarkInfo.previewSize.height
  } else {
    ratio = previewSize / watermarkInfo.previewSize.width
  }

  return {
    opacity: watermarkInfo.opacity / 100,
    width: watermarkInfo.size.width * ratio,
    height: watermarkInfo.size.height * ratio,
    top: watermarkInfo.position.y * ratio,
    left: watermarkInfo.position.x * ratio,
  }
}

type Props = {
  photo: PhotoType
  src: string
  onEnlargePhoto: (photo: PhotoType) => void
  onSplitGroup: (id: string) => void
  onDuplicatePhoto: (id: string) => void
  onDeletePhoto: (id: string) => void
}

const Photo: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const imgElement = useRef<HTMLImageElement>(null)

  const watermarkInfo = useSelector(
    (state: AppState) => state.edit.watermarkInfo
  )

  const photoId = props.photo.id
  const photoName = props.photo.name
  const onSplitGroup = props.onSplitGroup
  const onDuplicatePhoto = props.onDuplicatePhoto
  const onDeletePhoto = props.onDeletePhoto

  const onEnlargePhoto = (): void => {
    props.onEnlargePhoto(props.photo)
  }

  const onSplitHandler = React.useCallback((): void => {
    onSplitGroup(photoId)
  }, [onSplitGroup, photoId])

  const onDuplicateHandler = React.useCallback((): void => {
    onDuplicatePhoto(photoId)
  }, [onDuplicatePhoto, photoId])

  const onDeleteHandler = React.useCallback((): void => {
    onDeletePhoto(photoId)
  }, [onDeletePhoto, photoId])

  const Watermark = React.useCallback(
    ({ className }: { className: string }): React.ReactElement => {
      if (photoName !== '001' || watermarkInfo.src === '') {
        return <></>
      }

      const style = getWatermarkStyle(watermarkInfo, 163)

      return (
        <img
          style={style}
          src={watermarkInfo.src}
          className={className}
          alt="watermark"
        />
      )
    },
    [photoName, watermarkInfo]
  )

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.photo}>
        <span className={classes.title}>{props.photo.name}</span>
        <div className={classes.imageContainer}>
          <img
            src={props.src}
            className={classes.img}
            ref={imgElement}
            alt=""
          />
          <Watermark className={classes.watermark} />
        </div>
        <div className={classes.overlay}>
          <IconButton
            color="primary"
            className={classes.actionIcon}
            title="拡大"
            onClick={onEnlargePhoto}
          >
            <ZoomOutMapIcon fontSize="large" />
          </IconButton>
          <IconButton
            color="primary"
            className={classes.actionIcon}
            title="グループ分割"
            onClick={onSplitHandler}
          >
            <SubdirectoryArrowRightIcon fontSize="large" />
          </IconButton>
          <IconButton
            color="primary"
            className={classes.actionIcon}
            title="複製"
            onClick={onDuplicateHandler}
          >
            <FileCopyIcon fontSize="large" />
          </IconButton>
          <IconButton
            color="secondary"
            className={classes.actionIcon}
            title="削除"
            onClick={onDeleteHandler}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
        </div>
      </Paper>
    </div>
  )
}

export default React.memo(Photo)
