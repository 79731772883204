import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'

import { useDispatch } from 'react-redux'
import { editActions } from '../../redux/actions/editAction'

import Header from './HistoryHeader'
import Content from './Content'
import Dialog from '../../components/Dialog'
import API from '../../utils/api'
import { GraphData } from '../../utils/Types'

const History: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [userInfo, setUserInfo] = useState<Record<string, unknown> | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState(true)
  const [isCanCreate, setIsCanCreate] = useState(false)
  const [isOpenDialog, setIsOpendialog] = useState(false)
  const [datas, setDatas] = useState<GraphData[]>([])

  useEffect(() => {
    ;(async (): Promise<void> => {
      try {
        setIsLoading(true)

        const token = await getAccessTokenSilently({
          audience: 'http://localhost:3001',
        })

        let isUserInfoSetted = false
        if (userInfo === undefined) {
          const userinfo = await axios.get(
            'https://mst-com.us.auth0.com/userinfo',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          const res = await API.post('user', userinfo.data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          if (res.data.result) {
            setUserInfo(userinfo.data)
            isUserInfoSetted = true
          } else {
            setIsOpendialog(true)
            return
          }
        } else {
          isUserInfoSetted = true
        }

        if (isUserInfoSetted) {
          const res = await API.get('history', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          setDatas(res.data.history)
          setIsCanCreate(
            res.data.history.slice(-1)[0].y <
              Number(process.env.REACT_APP_DOWNLOAD_LIMIT)
          )
        }

        setIsLoading(false)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [getAccessTokenSilently])

  const onCreate = () => {
    dispatch(editActions.resetStore())
    props.history.push('/Edit/new')
  }

  return (
    <div>
      <Header
        onCreate={onCreate}
        isLoading={isLoading}
        isCanCreate={isCanCreate}
      />
      <Content datas={datas} />
      <Dialog
        open={isOpenDialog}
        title="エラー"
        content={
          <span>
            ユーザ情報の作成/取得に失敗しました。
            <br />
            画面を更新して再度お試しください。
          </span>
        }
        color="secondary"
        onClickOk={() => setIsOpendialog(false)}
      />
    </div>
  )
}

export default History
