import React from 'react'

import {
  CircularProgress,
  createStyles,
  makeStyles,
  Modal as ModalBase,
} from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    readingModalDiv: {
      display: 'table',
      width: '100%',
      height: '100%',
      color: '#EEEEEE',
      fontSize: '65px',
      textAlign: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    readingModalChild: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    readingModalText: {
      margin: '0 0 5px 10px',
    },
  })
)

type Props = {
  open: boolean
  message: string
  color: 'primary' | 'secondary' | undefined
}

const Modal: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <ModalBase
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.readingModalDiv}>
        <div className={classes.readingModalChild}>
          <CircularProgress size="60px" color={props.color} />
          <label className={classes.readingModalText}>{props.message}</label>
        </div>
      </div>
    </ModalBase>
  )
}

export default Modal
