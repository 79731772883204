import React from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'

import {
  createStyles,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'

import { ProtectedRoute } from './components/ProtectedRoute'
import Loading from './components/Loading'
import Header from './components/Header'
import List from './views/List/List'
import Edit from './views/Edit/Edit'
import History from './views/History/History'

import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles(() =>
  createStyles({
    view: {
      padding: '15px',
    },
  })
)

const App: React.FC = () => {
  const classes = useStyles()
  const theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#f48fb1',
      },
    },
  })

  const { isLoading, error, user } = useAuth0()

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <ThemeProvider theme={theme}>
      <Header name={user ? user.name : ''} />
      <div className={classes.view}>
        <Router>
          <Switch>
            {process.env.REACT_APP_MODE === '0' ? (
              <ProtectedRoute exact path="/" component={List} />
            ) : (
              <ProtectedRoute exact path="/" component={History} />
            )}

            <ProtectedRoute path="/Edit/:id" component={Edit} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default App
