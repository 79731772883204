import React from 'react'

import {
  Button,
  Dialog as DialogBase,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import { DialogProps } from '../utils/Types'

const Dialog: React.FC<DialogProps> = (props: DialogProps) => {
  return (
    <DialogBase
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.onClickCancel !== undefined ? (
          <Button color="default" onClick={props.onClickCancel}>
            キャンセル
          </Button>
        ) : (
          ''
        )}
        {props.onClickOk !== undefined ? (
          <Button color={props.color} onClick={props.onClickOk}>
            OK
          </Button>
        ) : (
          ''
        )}
      </DialogActions>
    </DialogBase>
  )
}

export default Dialog
