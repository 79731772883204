import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { editActions } from '../actions/editAction'
import { Group, PhotoSize, WatermarkInfo } from '../../utils/Types'

export interface EditState {
  photoSize: PhotoSize
  projectName: string
  groups: Group[]
  watermarkInfo: WatermarkInfo
  latestWatermark: string
}

const initialState: EditState = {
  photoSize: { width: 1000, height: 1000 },
  projectName: '',
  groups: [],
  watermarkInfo: {
    src: '',
    size: { width: 0, height: 0 },
    position: { x: 0, y: 0 },
    opacity: 50,
    previewSize: { width: 1000, height: 1000 },
  },
  latestWatermark: '',
}

export const EditReducer = reducerWithInitialState(initialState)
  .case(editActions.updatePhotoSize, (state, photoSize) => {
    return Object.assign({}, state, { photoSize })
  })
  .case(editActions.updateProjectName, (state, projectName) => {
    return Object.assign({}, state, { projectName })
  })
  .case(editActions.updateGroups, (state, groups) => {
    return Object.assign({}, state, { groups })
  })
  .case(editActions.updateGroup, (state, updateGroup) => {
    const groups = state.groups
    const updateGroupIndex = groups.findIndex(
      (group) => group.id === updateGroup.id
    )

    const first = groups.slice(0, updateGroupIndex)
    const last = groups.slice(updateGroupIndex + 1)

    if (updateGroup.photos.length !== 0) {
      return Object.assign({}, state, {
        groups: [...first, updateGroup, ...last],
      })
    } else {
      return Object.assign({}, state, { groups: [...first, ...last] })
    }
  })
  .case(editActions.updateWatermarkInfo, (state, watermarkInfo) => {
    return Object.assign({}, state, { watermarkInfo })
  })
  .case(editActions.clearWatermarkInfo, (state) => {
    return Object.assign({}, state, {
      watermarkInfo: {
        src: '',
        size: { width: 0, height: 0 },
        position: { x: 0, y: 0 },
        opacity: 50,
        previewSize: { width: 1000, height: 1000 },
      },
    })
  })
  .case(editActions.resetStore, (state) => {
    return Object.assign({}, initialState, {
      latestWatermark: state.latestWatermark,
    })
  })
  .case(editActions.updateLatestWatermark, (state, latestWatermark) => {
    return Object.assign({}, state, { latestWatermark })
  })
