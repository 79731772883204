import React from 'react'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
)

type Props = {
  onCreate: () => void
  isLoading: boolean
  isCanCreate: boolean
}

const Header: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" spacing={3} className={classes.root}>
      <Grid item md={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={
            props.isLoading || props.isCanCreate ? <NoteAddIcon /> : <></>
          }
          onClick={props.onCreate}
          disabled={props.isLoading || !props.isCanCreate}
        >
          {props.isLoading || props.isCanCreate
            ? '新規プロジェクト'
            : 'ダウンロード枚数上限に達しています'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default Header
